body {
    background-image: url(/images/tuning-wallpaper.jpg) !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-size: 100% 100% !important;
}

.login.login-4 .login-aside {
    background-image: url(/images/tuning-wallpaper.jpg);
    background-repeat: no-repeat;
    background-position: center;
}

#kt_footer {
    margin-top: 100px;
    position: fixed;
    bottom: 0px;
    width: 100%;
}

#kt_content {
    padding-bottom: 0px !important;
    min-height: 100%;
}

.header-top, .header-mobile {
    background-color: transparent !important;
}

.header-mobile, .header {
    min-height: 110px !important;
}

.header-mobile {
    border-bottom: 1px solid rgba(white, 0.1);
}

@media (max-width: 991.98px) {
    #kt_header {
        display:none;
    }
}

form {
    .btn {
        min-width: 100px;
    }
}

.swal2-cancel {
    color: black !important;
}

.swal2-container {
    border-color: transparent !important;
}

.swal2-popup .swal2-title {
    font-size: 18px !important;
}

.swal2-icon .swal2-icon-content {
    font-size: 65px !important;
}

.swal2-error {
    margin-bottom: 10px !important;
}

.card-body {
    padding-left: 25px;
    padding-right: 25px;
}

html {
    height: 100% !important;
}

